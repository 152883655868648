import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FileCaptureService } from 'app/service/file-capture.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileServiceService } from '../service/file-service.service';

@Component({
  selector: 'app-initiate-download',
  templateUrl: './initiate-download.component.html',
  styleUrls: ['./initiate-download.component.css']
})
export class InitiateDownloadComponent implements OnInit {
  error: boolean = false;
  @Input()
  showCaptcha: boolean | undefined;  
  captcha: string;                              // empty = not yet proven to be a human, anything else = human

  constructor(
    private route: Router, 
    public service: FileServiceService,  
    private fcservice : FileCaptureService,
    private ngxsippner : NgxSpinnerService
    ) {
      this.captcha = '';
      this.service.pageIndex = 5;
  }


  ngOnInit(): void {
    if (this.service.getCache('files')) {
      this.service.referenceFileDetails.files = this.service.getCache('files');
    }
  } 
// next() {
//   this.route.navigate(['confirmatiion']); 
// }
  next() {
    this.ngxsippner.show()
    let data = {
      BusinessName : this.service.referenceFileDetails.businessName,
      ContactName : this.service.referenceFileDetails.contactName,
      ContactPhone : this.service.referenceFileDetails.contactPhone,
      ContactEmail : this.service.referenceFileDetails.contactEmail,
      ReferenceId : this.service.referenceFileDetails.referenceId,
      CaseActivityKey : this.service.referenceFileDetails.caseActivityKey,
      ActiveAttachmentKey: this.service.referenceFileDetails.files.map((x: any) => x.attachmentKey)
  
    };
  
    this.fcservice.submitDocument(data).subscribe((res) => {
      this.ngxsippner.hide()
      if(res){
        this.route.navigate(['confirmatiion']); 
      }
      else{
        this.ngxsippner.hide();
        this.error = true;
      }
    }, error => {this.ngxsippner.hide()});
  }

  back() {
    this.route.navigate(['review-files']);
  }
  captchaEvent(captcha: string) {
    this.captcha = captcha;
  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
    console.log('resolved captcha with response: ' + this.captcha);
}

}