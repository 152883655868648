import { ChangeDetectorRef, Component, HostListener, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FileCaptureService } from 'app/service/file-capture.service';
import { FileSizePipe } from '../pipes/file-size.pipe';
import { FileServiceService } from '../service/file-service.service';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { ProgressService } from 'app/service/progress.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { NotificationService } from 'app/service/notification-service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  isFirst: any = true;
  files: any = [];
  showError: boolean = false;
  errorMsg: string = '';
  acceptedFiles: string = '.doc, .docx, .dcm, .pdf, .xls, .xlsx, .jpeg, .jpg, .bmp, .png, .tif, .tiff, .wav, .mp3, .txt, .text/plain, .mkv';
  FilesToUpload: any = [];
  newAttachments: any = [];
    canUpload : boolean = true;
    isViewOnly : boolean = false;
    isLoading : boolean = false;
   // notification = new Subject();
    BufferChunkSize: any;
    hasUpdate : boolean;
    isDisabled: boolean = false;
    notification : NotificationService;
    fileUploadMap : any = {};
    queue : any =[];
  constructor(private route: Router, private zone: NgZone, private fcservice : FileCaptureService, public service: FileServiceService,private filesize: FileSizePipe,
    private progressService: ProgressService,private http: HttpClient,
    public notificationService: NotificationService, private cdr: ChangeDetectorRef
  ) { 
    this.service.pageIndex = 3;

    
  }

  ngOnInit(): void {
   
    this.notification = this.notificationService;
    //this.progressService.startConnection();
    if(this.service.getCache('files')){
        this.files = this.service.getCache('files');
        this.service.referenceFileDetails.files = this.service.getCache('files');
        this.isDisabled = this.files.length === 0;
    }
    
  }

  next() {
    // this.files.forEach((file: File) => {
    //   const index = this.service.referenceFileDetails.files.findIndex(x=>x.name===file.name);
    //   if(index === -1) {
    //     this.service.referenceFileDetails.files.push(file);
    //   }
    // });
    this.route.navigate(['review-files']);
  }
  @HostListener('window:dragover', ['$event'])
  allowDrag(e) {
      
      e.dataTransfer.dropEffect = 'copy';
      //e.preventDefault();
  }

  @HostListener('window:drop', ['$event'])
  handleDrop(e) {
  //    e.preventDefault();
      
      if (this.newAttachments.length === 0) {
          this.uploadFiles(e.dataTransfer.files);
          //this.onUpload(e.dataTransfer.files);
      }
  }
  onFileChange(event: any) {
    const pFileList = event.files ? event.files : event;
    if(pFileList.length > 500){
        this.showError = true;
        this.errorMsg = "Maximum of 500 files can be uploaded";
        return;
    }
    for (let index = 0; index < pFileList.length; index++) {
      const element = pFileList[index];
      const [size, unit] = this.filesize.transform(element.size).split(' ');
      if(parseFloat(size) > 1.5 && unit === 'GB') {
        this.showError = true;
        this.errorMsg = "Files size should be less than 1.5 GB" + ', ' +  element.name;
         return;
      } else {
        this.files.push(element);
        this.showError = false;
      
        // return true;
      }
    }
    if (this.newAttachments.length === 0) {
        if (event === null || event === undefined)
            return;
        var files = event.files == undefined? event : event.files;
        this.isDisabled = true;
        this.uploadFiles(files);
        //this.onUpload(files);
    }
  }

  
  uploadFiles(files : any) {
    if (files.length > 0) {
         let i = 0;
         this.FilesToUpload = [];
        if (files[i].name !== undefined) {
            var attachment = { fileName: "", fileType: "", percentage: 0, fileSize: 0, sizeReadable: 0, isFirst: false };

            attachment.fileName = files[i].name.substring(files[i].name.lastIndexOf('\\') + 1);
            attachment.fileType = files[i].name.substring(files[i].name.lastIndexOf('.') + 1, files[i].name.length);

            this.FilesToUpload.push(attachment);
        }
        this.FilesToUpload[i].percentage = 0;
        this.FilesToUpload[i].noOfchunksSent = 0;
        this.FilesToUpload[i].fileSize = files[i].size !== undefined ? files[i].size : 0;
        this.FilesToUpload[i].fileId = i;
        this.FilesToUpload[i].sizeReadable = this.humanFileSize(this.FilesToUpload[i].fileSize, true);
        this.newAttachments.push(this.FilesToUpload[i]);
        this.FilesToUpload[i].activityName = 'Deconfliction';
        
        this.FilesToUpload[i].attachmentLength = attachment.fileSize;
        this.FilesToUpload[i].targetFile = files[i];
        this.FilesToUpload[i].referenceId = this.service.getCache('referenceId');
        attachment['referenceId'] = this.service.getCache('referenceId');
        if(this.service.getCache('attachmentSaved') == null){
            attachment.isFirst = true;
        }
       
        this.fcservice.addAttachmentToDB(attachment).subscribe(
            (data: any) => {
                if (data.attachmentKey === -1 || data.attachmentKey === 0) {
                    var idx = this.FilesToUpload.findIndex((x) => {
                      
                        return x.fileId === i;
                    });
                    if (idx > -1) {
                        this.FilesToUpload.splice(idx, 1);
                        this.newAttachments.splice(idx, 1);
                    }
                    //this.files.remove(this.FilesToUpload[i].targetFile);
                    this.files =this.service.getCache('files');
                    this.notification.add({
                        type: 'info',
                        title: 'Activity Attachment',
                        body: 'The upload of the attachment has failed. An attachment with same file name' + " " + attachment.fileName + " " + 'already exists on this activity.'
                    });
                    this.isDisabled = false;
                    this.cdr.detectChanges();
                }
                else {               
                    var idx1 = this.FilesToUpload.findIndex(function (x) {
                        return x.fileId === i;
                    });
                    if (idx1 > -1) {
                        this.FilesToUpload[idx1].attachmentKey = data.attachmentKey; 
                        if(data.attachmentKey && this.service.getCache('attachmentSaved') == null){
                            this.service.setCacheForitems([{id:"attachmentSaved", value:"first"}]);
                        }
                        this.service.referenceFileDetails.caseActivityKey = data.caseActivityKey;
                      
                            let index =  this.service.referenceFileDetails.files.findIndex((x : any)=>x.fileName===this.FilesToUpload[idx1].fileName);
                            if(index === -1 ) {
                              this.service.referenceFileDetails.files.push(this.FilesToUpload[idx1]);
                              this.service.setCacheForitems([{id:"files", value:this.service.referenceFileDetails.files}]);
                            }

                            //    let cindex = this.service.getCache('files'].findIndex((x : any)=>x.fileName===this.FilesToUpload[idx1].fileName);
                            // if(cindex === -1) {
                            //   //this.service.referenceFileDetails.files.push(this.FilesToUpload[idx1]);
                            //   this.service.setCacheForitems([{id:"files", value:this.service.referenceFileDetails.files}]);
                            // }
                       
                        this.processFiles(i);
                        if(files.length > 1){
                            this.zone.run(() => {
            
                                this.canUpload = false;
                                for (var i = 1; i < files.length; i++) {
                                    if (files[i].name !== undefined) {
                                        var attachment = { fileName: "", fileType: "", percentage: 0, fileSize: 0, sizeReadable: 0 };
                
                                        attachment.fileName = files[i].name.substring(files[i].name.lastIndexOf('\\') + 1);
                                        attachment.fileType = files[i].name.substring(files[i].name.lastIndexOf('.') + 1, files[i].name.length);
                
                                        this.FilesToUpload.push(attachment);
                                    }
                                    this.FilesToUpload[i].percentage = 0;
                                    this.FilesToUpload[i].fileName = files[i].name;
                                    this.FilesToUpload[i].noOfchunksSent = 0;
                                    this.FilesToUpload[i].fileSize = files[i].size !== undefined ? files[i].size : 0;
                                    this.FilesToUpload[i].fileId = i;
                                    this.FilesToUpload[i].sizeReadable = this.humanFileSize(this.FilesToUpload[i].fileSize, true);
                                    this.newAttachments.push(this.FilesToUpload[i]);
                                    this.FilesToUpload[i].activityName = 'Deconfliction';
                                    this.FilesToUpload[i].referenceId = this.service.getCache('referenceId');
                                    this.addAttachmentToDb(this.FilesToUpload[i], files[i], i);                    
                                }
                            
                        });
                        }
                    }
                }
              
            });
    }
    
    
    }

    humanFileSize(bytes : any, si : any) {
        var thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + ' ' + units[u];
    }

    addAttachmentToDb (attachment, targetFile, i) {        
        this.FilesToUpload[i].attachmentLength = attachment.fileSize;
        this.FilesToUpload[i].targetFile = targetFile;
        this.FilesToUpload[i].referenceId = this.service.getCache('referenceId');
        
            attachment.isFirst = false;
            
        this.fcservice.addAttachmentToDB(attachment).subscribe(
            (data: any) => {
                if (data.attachmentKey === -1 || data.attachmentKey === 0) {
                    var idx = this.FilesToUpload.findIndex(function (x) {
                        return x.fileId === i;
                    });
                    if (idx > -1) {
                        this.FilesToUpload.splice(idx, 1);
                        this.newAttachments.splice(idx, 1);
                    }
                }
                else {               
                    var idx1 = this.FilesToUpload.findIndex(function (x) {
                        return x.fileId === i;
                    });
                    if (idx1 > -1) {
                        this.FilesToUpload[idx1].attachmentKey = data.attachmentKey;
                        this.service.referenceFileDetails.caseActivityKey = data.caseActivityKey;
                        let index = this.service.referenceFileDetails.files.findIndex(x=>x.name===this.FilesToUpload[idx1].fileName);
                        if(index === -1) {
                          this.service.referenceFileDetails.files.push(this.FilesToUpload[idx1]);
                          this.service.setCacheForitems([{id:"files", value:this.service.referenceFileDetails.files}]);
                        }
                        this.processFiles(i);
                    }
                }
              
            });
    }

    nextBatchProcess (i) {
        var remainder = this.FilesToUpload[i].fileChunksLength % 5;
        var num = 5;
        var total = this.FilesToUpload[i].fileChunks.length;
        if (total === remainder || total < 5) {
            num = this.FilesToUpload[i].fileChunks.length;
        }

        for (var countChunk = 0; countChunk < total; countChunk++) {
            if (this.FilesToUpload[i].fileChunks[countChunk] != null) {
                this.upload(this.FilesToUpload[i].fileChunks[countChunk].targetFile,
                    this.FilesToUpload[i].fileChunks[countChunk].id,
                    null,
                    this.FilesToUpload[i].fileSize,
                    this.FilesToUpload[i].fileName,
                    this.FilesToUpload[i].fileChunksLength,
                    this.FilesToUpload[i].attachmentKey,
                    i
                );
            }
        }
    }

    upload (chunk, chunkorfileId, objXhr1, fileSize, fileName, totalChunks, attachmentKey, itemindex) {    
      var baseUrl = environment.baseUri;    
        var url =
        baseUrl + "FileCapture/UploadAttachment?attachmentKey="+attachmentKey+"&referenceId="+this.service.referenceFileDetails.referenceId+"&caseactivitykey="+this.service.referenceFileDetails.caseActivityKey;

        var objXhr;
        objXhr = new XMLHttpRequest();
        var data = new FormData();

        data.append('uploadedFile', chunk);
        objXhr.upload.fileId = chunkorfileId;
        objXhr.upload.fileName = fileName;
       
        objXhr.timeout = 3600000;
        objXhr.open('POST', url);
        
        objXhr.setRequestHeader("X-Content-Name", fileName);
        //if its a small file no need for custom headers
        if (totalChunks !== 0) {

            objXhr.setRequestHeader("X-Chunk-Id", chunkorfileId);
           // objXhr.setRequestHeader("X-Content-Id", tokenResult.token);
            objXhr.setRequestHeader("X-Content-Length", fileSize);
            objXhr.setRequestHeader("X-Total-Chunks", totalChunks);

            if (chunkorfileId === totalChunks - 1) {
                objXhr.setRequestHeader("X-Last-Chunk", true);
            }
            else
                objXhr.setRequestHeader("X-Last-Chunk", false);
        }
        
        if (fileSize <= (1024 * 1024 * 50)) {
            this.FilesToUpload[chunkorfileId].xmlObj = objXhr;
            objXhr.upload.addEventListener("progress", (evt) => this.updateProgressForSmallFiles(evt), false);
        }
        else
            objXhr.upload.addEventListener("progress", (evt) => this.updateProgress(evt), false);


        objXhr.onload = () => {
            if(objXhr.status>= 200 && objXhr.status < 300 ){
                this.zone.run(() => {
                    if (fileSize > (1024 * 1024 * 50)) {
                        if(this.fileUploadMap[attachmentKey] === undefined){
                            this.fileUploadMap[attachmentKey] = 1;
                        }else{
                            this.fileUploadMap[attachmentKey]++;
                        }
                        if (totalChunks === this.fileUploadMap[attachmentKey]) {
                            this.isDisabled = false;   
                            this.newAttachments = [];  
                            this.fcservice.commitFile(attachmentKey,fileName,totalChunks).subscribe(
                                (data: any) => {
                                    this.notification.add({
                                        type: 'info', title: 'File', body: 'File ' + fileName + ' has been uploaded.'
                                    });
                                });              
                        
                        }
                    }else{
                        this.notification.add({
                            type: 'info', title: 'File', body: 'File ' + fileName + ' has been uploaded.'
                        });
                        this.isDisabled = false;   
                        this.newAttachments = [];  
                    }
                    
                });
            }
        }
        objXhr.send(data);
    }

    updateProgressForSmallFiles(e) {
        var filename = e.currentTarget.fileName;
        let keyOfFilesmall;
        this.FilesToUpload.forEach((value, key) => {
            if (value.fileName === filename)
                keyOfFilesmall = key;
        });
        this.zone.run(() => {
            var percent = 100;
            this.newAttachments[keyOfFilesmall].percentage = percent.toFixed(0);            
            if (this.FilesToUpload[keyOfFilesmall].fileName === this.newAttachments[keyOfFilesmall].fileName) {
                this.FilesToUpload[keyOfFilesmall].isReadyToDownload = true;
                this.newAttachments[keyOfFilesmall].percentage = 100;
               
            }
            //To-DO
            // this.notification.next({
            //     type: 'info', title: this.deconflictionTranslate['File'], body: this.deconflictionTranslate['File '] + this.newAttachments[keyOfFilesmall].fileName + this.deconflictionTranslate[' has been uploaded.']
            // });
            this.hasUpdate = true;                            
        });        
    }

    createandUploadChunks (attachment, targetFile, i) {
        var fileChunks = [];
        var Size = attachment.fileSize;
        var num = (1024 * 1024) * 50;        
        if (Size / num > 256) {
            this.BufferChunkSize = Size / 256;
        }
        else
            this.BufferChunkSize = (1024 * 1024) * 50;        
        var ReadBuffer_Size = 1024;
        var FileStreamPos = 0;
        var EndPos = this.BufferChunkSize;

        var fileChunkId = 0;
        while (FileStreamPos < Size) {
            fileChunks.push({ targetFile: targetFile.slice(FileStreamPos, EndPos), id: fileChunkId });
            FileStreamPos = EndPos;
            EndPos = FileStreamPos + this.BufferChunkSize;
            fileChunkId++;
        }
        this.FilesToUpload[i].fileChunksLength = fileChunks.length;
        this.FilesToUpload[i].fileChunks = fileChunks;
        this.FilesToUpload[i].lastFileChunkId = fileChunkId;


    };

    processFiles (i) {
        //this.newAttachments = this.FilesToUpload; //2MB
        if (this.FilesToUpload[i].attachmentKey && Math.abs(parseInt(this.FilesToUpload[i].fileSize)) >= 1024 * 1024 * 50) {
            this.createandUploadChunks(this.FilesToUpload[i], this.FilesToUpload[i].targetFile, i);
            this.nextBatchProcess(i);
           
        }
        else {
            this.upload(this.FilesToUpload[i].targetFile, i, null, this.FilesToUpload[i].fileSize, this.FilesToUpload[i].fileName,
                0, this.FilesToUpload[i].attachmentKey, i);
        }
    }

    checkFileType (fileType) {
        switch (fileType.toLowerCase()) {
            case "doc":
                return true;
            case "docx":
                return true;
            case "pdf":
                return true;
            case "xls":
                return true;
            case "xlsx":
                return true;
            case "jpeg":
                return true;
            case "jpg":
                return true;
            case "bmp":
                return true;
            case "png":
                return true;
            case "tif":
                return true;
            case "tiff":
                return true;
            case "wav":
                return true;
            case "mp3":
                return true;
            case "txt":
                return true;
            case "text/plain":
                return true;
            case "htm":
                return true;
            case "html":
                return true;
            case "csv":
                return true;
            case "ppt":
                return true;
            case "pptx":
                return true;
            default:
                return false;
        }
    }    

    updateProgress(e) {
        var chunkid = e.currentTarget.fileId;
        var filename = e.currentTarget.fileName;
        
        let keyOfFile;

        this.FilesToUpload.forEach((value, key) => {
            if (value.fileName === filename) {
                keyOfFile = key;       
            }
        });
        
        this.zone.run(() => {
            var perc = ((this.FilesToUpload[keyOfFile].noOfchunksSent * this.BufferChunkSize) / this.FilesToUpload[keyOfFile].fileSize) * 100;            
            if (perc < 100)
                this.newAttachments[keyOfFile].percentage = perc.toFixed(0);
            else if(perc >= 100 && this.FilesToUpload[keyOfFile].fileChunksLength === this.FilesToUpload[keyOfFile].noOfchunksSent) {                
                if (this.FilesToUpload[keyOfFile].fileName === this.newAttachments[keyOfFile].fileName) {
                    this.FilesToUpload[keyOfFile].isReadyToDownload = true;
                    this.newAttachments[keyOfFile].percentage = 100;
                  
                }
                // this.notification.next({
                //     type: 'info', title: this.deconflictionTranslate['File'], body: this.deconflictionTranslate['File '] + this.newAttachments[keyOfFile].fileName + this.deconflictionTranslate[' has been uploaded.']
                // });
                this.hasUpdate = true;                
            }
        });
        
        this.FilesToUpload[keyOfFile].noOfchunksSent++;  
        var remainder = this.FilesToUpload[keyOfFile].fileChunksLength % 5;
        var idx = this.FilesToUpload[keyOfFile].fileChunks.findIndex(function (x) {
            return x.id === chunkid;
        });
        
        if (idx > -1) {
            this.FilesToUpload[keyOfFile].fileChunks.splice(idx, 1);                      
        }

        if (this.FilesToUpload[keyOfFile].fileChunksLength === this.FilesToUpload[keyOfFile].noOfchunksSent) {
            if (this.FilesToUpload[keyOfFile].isReadyToDownload === true) { 
                this.newAttachments[keyOfFile].percentage = 100;                
             }
        }
    }  

  back() {
    this.route.navigate(['reference']);
  }

  onUpload(files : any): void {
    var i=0;
    for (const file of files) {
        const formData = new FormData();        
        var jsonData = JSON.stringify({ 
				referenceid : this.service.getCache('referenceId'),
				activityName : 'Deconfliction',
				isFirst : i==0	
			});
        
        formData.append('jsonData', jsonData);
		formData.append('files', file, file.name);
		i++;
      this.fcservice.addFilesToDB(formData).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          console.log('Upload Progress: ' + Math.round(100 * event.loaded / event.total) + '%');
        } else if (event.type === HttpEventType.Response) {
          console.log('Upload complete');
        }
      });
    }
    
  }
  closeNotification(index: number) {
    this.notification.queue.splice(index, 1);
  }
}
