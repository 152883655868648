<app-header></app-header>

<div class="col-md-3 offset-md-1 mb-5">
  <div class="row">
    <span class="text-white ref">Reference ID</span>
  </div>
  <div class="row">
    <span class="ref-id-no">{{ service.referenceFileDetails.referenceId }}</span>
  </div>
</div>
<h4 >
  <span class="offset-md-2"> Thank You!</span>
</h4>
<i class="offset-md-2 fa-solid fa-check"></i>

  <div class="col-md-4 text-white-lite offset-md-1 pad solid mb-4">
    <div>
        <label class="text-white-ulte offset-md-2">Reference ID:</label>
        <span class="text-white-sub">{{ service.referenceFileDetails.referenceId }}</span>
    </div>
    <div>
        <label class="text-white-ulte offset-md-2">Date Submitted:</label>
    <span class="text-white-sub">{{ date | date }}</span>
    </div>
    <div>
        <label class="text-white-ulte offset-md-2">Number of Files Submitted:</label>
    <span class="text-white-sub">{{ service.referenceFileDetails.files.length }}</span>
    </div>
  </div>

<div class="row">
  <div class="col-md-10 offset-md-1 text-white-sub mb-4">
    You will receive a Confirmation Email once all files are successfully transferred. If at any time you wish to check the
    status of the files, or need to upload additional files, please go back to the FileCapture home page and use the
    Reference ID found in the request letter.
  </div>
</div>

<div class="offset-md-1 confirmationfooter">
  <button type="button" class="btn-enter" id="dashboardButton" (click)="exit()">Exit</button>
</div>
<div class="col-lg-11 col-md-11 col-sm-12 offset-md-1">   
    <span>If you have questions regarding the letter you received requesting information, please locate the contact information contained in that letter and reach out to the appropriate party. </span> <br>             
    <span>For questions or issues with this website, please contact filecapturesupport@gainwelltechnologies.com. Please list the Reference ID you entered in your email.</span>
  <div class="row">
    <div class="text-white-s ml-5">
        <a href="https://www.gainwelltechnologies.com/terms-of-use/" target="_blank" class="text-white-s">Terms of Use</a> | 
        <a href="https://www.gainwelltechnologies.com/privacy/" target="_blank" class="text-white-s">Privacy Policy</a> | 
        <a href="https://www.gainwelltechnologies.com/cookies-policy/" target="_blank" class="text-white-s">Cookie Policy</a> |
    </div>
  </div>
  </div>