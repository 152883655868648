<div class="footer">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 mt-3 mb-1">
            <span class="text-white-lite">For questions or issues with this website, please contact filecapturesupport@gainwelltechnologies.com. Please list the Reference ID you entered in your email.</span>
        </div>
    </div>
    <div class="row">
        <div class="text-white-s ml-5">
            <a href="https://www.gainwelltechnologies.com/terms-of-use/" target="_blank" class="text-white-s">Terms of Use</a> | 
            <a href="https://www.gainwelltechnologies.com/privacy/" target="_blank" class="text-white-s">Privacy Policy</a> | 
            <a href="https://www.gainwelltechnologies.com/cookies-policy/" target="_blank" class="text-white-s">Cookie Policy</a> |
        </div>
    </div>
</div>
